import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tab, Tabs, Form, Alert } from "react-bootstrap";
import {
  HandleChange, GetAllItems, CheckFormValidity, CheckFieldValidity,
  DefaultSelectValue
} from "../../utility/Form";
import CommonAddItemComp from "../common/Add";
import Select from 'react-select';
import { set, isEmpty, get, find, filter, merge, sortBy } from "lodash";
import DatePicker from 'react-date-picker';
import { v4 as uuidv4 } from 'uuid';
import ModalPopUp from "../common/modal/Modal";
import StatcTable from "../common/StaticTable";
import TextEditor from "../text-editor";
import CurrencyJson from "../../config/currency.json";
import GoogleMap from "../google-map/map";
import CurrencyFormat from 'react-currency-format';
import AreasJson from "../../config/areas.json";
import { isBrowser } from "../common/ggfx-client/utility";
import { getLoggedInUserOffices, getUser } from "../../utility/Auth";
import ConfirmOnExitComp from "../common/ConfirmOnExit";
const FORM_ID = uuidv4();

const AddFormControls = (props) => {
  //Default fields values
  const [DefaultFields, SetDefaultFields] = useState({
    price: null,
    completed_date: null,
    exchanged_date: null,
    bedroom: null,
    bathroom: null,
    reception: null,
    floorarea_min: null,
    floorarea_max: null,
    amenities: [],
  });

  const RentalStatusOptions = [
    { value: "Let", label: "Let" },
    { value: "Let Agreed", label: "Let Agreed" },
    { value: "PostPoned", label: "PostPoned" },
    { value: "To Let", label: "To Let" },
    { value: "Withdrawn", label: "Withdrawn" },
  ];
  const SaleStatus = [
    { value: "Exchanged", label: "Exchanged" },
    { value: "For Sale", label: "For Sale" },
    { value: "PostPoned", label: "PostPoned" },
    { value: "Sold", label: "Sold" },
    { value: "Sale Agreed", label: "Sale Agreed" },
    { value: "Withdrawn", label: "Withdrawn" },
  ]

  const BuildingsList = {
    residential: {
      sales: [
        { value: "Apartment", label: "Apartment" },
        { value: "Bungalow", label: "Bungalow" },
        { value: "Cottage", label: "Cottage" },
        { value: "Country House", label: "Country House" },
        { value: "Detached House", label: "Detached House" },
        { value: "Dormer", label: "Dormer" },
        { value: "Duplex", label: "Duplex" },
        { value: "End of Terrace", label: "End of Terrace" },
        { value: "Farm", label: "Farm" },
        { value: "Holiday Home", label: "Holiday Home" },
        { value: "House", label: "House" },
        { value: "Investment", label: "Investment" },
        { value: "Live-Work Unit", label: "Live-Work Unit" },
        { value: "Period House", label: "Period House" },
        { value: "Semi-Detached House", label: "Semi-Detached House" },
        { value: "Site", label: "Site" },
        { value: "Terraced House", label: "Terraced House" },
        { value: "Townhouse", label: "Townhouse" },
      ],
      lettings: [
        { value: "Apartment", label: "Apartment" },
        { value: "House", label: "House" },
        { value: "Studio", label: "Studio" },
      ]
    },
    commercial: {
      default: [
        { value: "Business", label: "Business" },
        { value: "Development Land", label: "Development Land" },
        { value: "Farm Land", label: "Farm Land" },
        { value: "Hotels & B+Bs", label: "Hotels & B+Bs" },
        { value: "Industrial", label: "Industrial" },
        { value: "Investment", label: "Investment" },
        { value: "Live-Work Unit", label: "Live-Work Unit" },
        { value: "Office", label: "Office" },
        { value: "Parking Space", label: "Parking Space" },
        { value: "Pubs & Restaurants", label: "Pubs & Restaurants" },
        { value: "Retail", label: "Retail" },
      ]
    },
    'new_developments_residential': {
      sales: [
        { value: "Apartment", label: "Apartment" },
        { value: "Bungalow", label: "Bungalow" },
        { value: "Cottage", label: "Cottage" },
        { value: "Country House", label: "Country House" },
        { value: "Detached House", label: "Detached House" },
        { value: "Dormer", label: "Dormer" },
        { value: "Duplex", label: "Duplex" },
        { value: "End of Terrace", label: "End of Terrace" },
        { value: "Farm", label: "Farm" },
        { value: "Holiday Home", label: "Holiday Home" },
        { value: "House", label: "House" },
        { value: "Investment", label: "Investment" },
        { value: "Live-Work Unit", label: "Live-Work Unit" },
        { value: "Period House", label: "Period House" },
        { value: "Semi-Detached House", label: "Semi-Detached House" },
        { value: "Site", label: "Site" },
        { value: "Terraced House", label: "Terraced House" },
        { value: "Townhouse", label: "Townhouse" }
      ]
    }
  }

  const [dateValue, setDateValue] = useState(null);
  const [facilities, setFacilities] = useState([""]);
  const [highLightFeatures, setHighLightFeatures] = useState([""]);
  const [uploadedData, setUploadedData] = useState([]);
  const [allUploadedItems, setAllUploadedItems] = useState([]);
  set(DefaultFields, 'country', "IE");
  const [agentNotes, setAgentNotes] = useState([""]);
  const [currencyOption, setCurrencyOption] = useState();
  const [completedDate, setCompletedDate] = useState(null)
  const [exchangedDate, setExchangedDate] = useState(null);

  const MoveToTopOfPage = () => {
    if (isBrowser()) {
      window.scrollTo(0, 0)
    }
  }

  //Get settings
  const GetSettings = async () => {
    try {
      const { data } = await GetAllItems('settings.getAll', { query: "_limit=-1" });
      setCurrencyOption(data?.results[0]?.currency)
      set(DefaultFields, 'currency', data?.results[0]?.currency);
    } catch (error) {
      console.log('AddFormControls GetSettings error', error);
    }
  };

  //Get facilities
  const GetFecilities = async () => {
    try {
      const { data } = await GetAllItems('facilities.getAll', { query: "_limit=-1&_sort=facility:ASC" });
      setFacilities(data?.results?.map(facility => { return { value: facility.facility, label: facility.facility } }))
    } catch (error) {
      console.log('AddFormControls GetFecilities error', error);
    }
  };

  //Get Areas
  const [countyJSON, setCountyJSON] = useState([]);
  const [county, setCounty] = useState();
  const [subCountyJSON, setSubCountyJSON] = useState([]);
  const [subCounty, setSubCounty] = useState('');
  const [townJSON, setTownJSON] = useState([]);
  const [town, setTown] = useState('');
  const [isFormSaveTriggered, setIsFormSaveTriggered] = useState(false);

  const GetAreas = async () => {
    try {
      let countyList = [];
      filter(AreasJson, function (area) {
        if (area.level === 1) {
          countyList.push({ label: area.name, value: area.name })
          return 1;
        }
      })
      setCountyJSON(countyList);
    } catch (error) {
      console.log('AddFormControls GetAreas error', error);
    }
  };

  //Get Offices
  const [officesList, setOfficesList] = useState([]);
  const [offices, setOffices] = useState([]);
  const [office, setOffice] = useState();
  const [loggedInUserOffices, setLoginUserOffices] = useState(getLoggedInUserOffices());
  const [loginUser, setLoginUser] = useState(getUser());
  const GetOffices = async () => {
    try {
      const { data } = await GetAllItems('offices.getMyHomeOffices',
        { query: '_limit=-1' });
      let Offices = [];
      data.filter(office => {
        if (office['my_home_id']) {
          if (loginUser.hasSuperAdminRole) {
            Offices.push({
              value: office['my_home_id'],
              label: `${office['Office_Name']} ${office['Branch_Location'] ? (`(${office['Branch_Location']})`) : ""}`,
              id: office.id,
              isDisabled: (!office['Publish']) || (!office.published_at)
            });
          } else {
            Offices.push({
              value: office['my_home_id'],
              label: `${office['Office_Name']} ${office['Branch_Location'] ? (`(${office['Branch_Location']})`) : ""}`,
              id: office.id,
              isDisabled: (isEmpty(find(loggedInUserOffices, function (userOffice) {
                return userOffice['my_home_id'] === office['my_home_id']
              })) || (!office['Publish']) || (!office.published_at))
            });
          }
        }
        return 1;
      });
      setOffices(Offices);
      setOfficesList(data);
    } catch (error) {
      console.log('AddFormControls GetOffices error', error);
    }
  };

  //Get Teams for negotiator
  const [negotiators, setNegotiators] = useState([]);
  const [negotiator, setNegotiator] = useState();
  const GetNegotiators = async (officeId) => {
    try {
      // Get the office by id to list negotiators
      let Negotiators = [];
      officesList.filter(office => {
        if (office.id === officeId) {
          Negotiators = office['Teams'].map(negotiator => {
            return {
              value: negotiator['crm_id'], label: negotiator['Name'], Email: negotiator['Email'],
              isDisabled: (!negotiator['Publish']) || (!negotiator.published_at) || (!negotiator['crm_id'])
            }
          });
        }
        return 1;
      });
      setNegotiators(Negotiators);
    } catch (error) {
      console.log('AddFormControls GetNegotiators error', error);
    }
  };

  const [newDevelopments, setNewDevelopments] = useState([]);
  const [newDevelopment, setNewDevelopment] = useState();
  const GetNewDevelopments = async () => {
    try {
      const { data } = await GetAllItems('new-developments.getAllNewDev',
        { query: '_limit=-1&crm_provider=property_uploader&deleteAttempt=0' });
      let newDevelopments = [];
      data.filter(newDev => {
        newDevelopments.push({
          value: newDev['id'],
          label: `${newDev['crm_id']} ${newDev['house_number'] ? `(${newDev['house_number']})`: ''}`,
          id: newDev.id,
          isDisabled: (!newDev.published_at)
        });
        return 1;
      });
      setNewDevelopments(newDevelopments);
    } catch (error) {
      console.log('AddFormControls GetOffices error', error);
    }
  };

  useEffect(() => {
    GetSettings();
    GetFecilities();
    GetAreas();
    GetOffices();
    GetNewDevelopments();
  }, []);

  useEffect(() => {
    let UploadedItems = [...allUploadedItems].filter(item => !isEmpty(item));
    if (uploadedData.isUpdate || uploadedData.isDelete) {
      let itemsByField = [], itemsByOtherFields = [];
      UploadedItems.forEach(element => {
        if (element.field === uploadedData.field)
          return itemsByField.push(element);
        itemsByOtherFields.push(element);
      });
      if (uploadedData.isUpdate) {
        itemsByField = itemsByField.map((item, index) => {
          if (index === uploadedData.index) {
            const ItemMerged = { ...item, ...uploadedData };
            delete ItemMerged.isUpdate;
            return ItemMerged;
          }
          return item;
        });
      } else if (uploadedData.isDelete) {
        itemsByField = itemsByField.filter((item, index) => {
          if (index === uploadedData.index) {
            return false;
          }
          return item;
        });
      }
      UploadedItems = itemsByField.concat(itemsByOtherFields);
    } else if (!isEmpty(uploadedData) && !isEmpty(uploadedData.items)) {
      //concat 2 arrays
      UploadedItems = UploadedItems.concat(uploadedData.items);
    }
    UploadedItems = sortBy(UploadedItems, ['field', 'order']);
    set(DefaultFields, `${uploadedData.field}`, [...UploadedItems]
      .filter(item => (item.field === uploadedData.field)));
    setAllUploadedItems([...UploadedItems].filter(item => !isEmpty(item)));
  }, [uploadedData]);

  const [mapLoc, setMapLoc] = useState({ lat: 51.509865, lng: -0.118092 });
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [addressForMap, setAddressForMap] = useState([]);
  const [address1, setAddress1] = useState();
  const [houseNumber, setHouseNumber] = useState();
  const [postCode, setPostCode] = useState();
  const [displayAddress, setDisplayAddress] = useState();
  const [mapActionType, setMapActionType] = useState('lat-lng');

  const getMapLocation = (loc) => {
    setMapLoc(loc);
    setLatitude(loc.lat);
    setFormError({ ...formError, ...{ latitude: { hasError: false } } });
    setLongitude(loc.lng);
    setFormError({ ...formError, ...{ longitude: { hasError: false } } });
    setDisplayAddress(loc.display_address);
  }

  const addHighLightFeatures = (index) => {
    if (highLightFeatures[index]) {
      setHighLightFeatures([...highLightFeatures, ""]);
    } else {
      alert('Please fill!');
    }
  }

  const removeHighLightFeatures = (index) => {
    let newHighLightFeatures = [...highLightFeatures]
    newHighLightFeatures.splice(index, 1);
    setHighLightFeatures(newHighLightFeatures);
  }

  const handleHighLightFeaturesChange = (event, index) => {
    let newHighLightFeatures = [...highLightFeatures];
    newHighLightFeatures[index] = event.target.value;
    setHighLightFeatures(newHighLightFeatures);
  }

  const handleHighLightFeaturesKP = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault();
      addHighLightFeatures(index);
    }
  }

  const handleAgentNotesChange = (event, index) => {
    let newAgentNotes = [...agentNotes];
    newAgentNotes[index] = event.target.value;
    setAgentNotes(newAgentNotes);
  }

  //Rooms 
  const [room_details, setroom_details] = useState([{
    name: "",
    dimensions: "",
    description: "",
  }]);
  const addroom_details = (index) => {
    if (room_details[index]) {
      const NewRoomDetail = {
        name: "",
        dimensions: "",
        description: "",
      };
      setroom_details([...room_details, NewRoomDetail]);
    } else {
      alert('Please fill!');
    }
  }

  const removeroom_details = (index) => {
    let newRooms = [...room_details];
    newRooms.splice(index, 1);
    setroom_details(newRooms);
  }

  const handleroom_detailsChange = (event, index, name) => {
    let newRooms = [...room_details];
    newRooms[index][name] = event.target.value;
    setroom_details(newRooms);
  }

  const handleroom_detailsKP = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault();
      setroom_details(index);
    }
  }

  const getUploadedData = (uploadedDataFromModal) => {
    setUploadedData(uploadedDataFromModal);
  }

  const [showModalImages, setShowModalImages] = useState(false);
  const [showModalFloorplan, setShowModalFloorplan] = useState(false);
  const [showModalEPC, setShowModalEPC] = useState(false);
  const [showModalBrochure, setShowModalBrochure] = useState(false);

  const showModalImagesFunc = () => {
    setShowModalImages(true);
  }

  const setShowModalImagesFunc = () => {
    setShowModalImages(false);
  }

  const showModalFloorplanFunc = () => {
    setShowModalFloorplan(true);
  }

  const setShowModalFloorplanFunc = () => {
    setShowModalFloorplan(false);
  }

  const showModalEPCFunc = () => {
    setShowModalEPC(true);
  }

  const setShowModalEPCFunc = () => {
    setShowModalEPC(false);
  }

  const showModalBrochureFunc = () => {
    setShowModalBrochure(true);
  }

  const setShowModalBrochureFunc = () => {
    setShowModalBrochure(false);
  }

  // This state used to store form errors
  //form error has form error and each field error status but now field level error
  const [formError, setFormError] = useState({});
  const CheckFormFieldValidity = (event, isSelectBox = false) => {
    const FieldError = CheckFieldValidity(event, isSelectBox);
    setFormError({ ...formError, ...FieldError });
  }

  const HandleChangeFunc = (event) => {
    HandleChange(event);
    if (event?.target?.name === 'crm_id') {
      setCrmId(event?.target?.value);
    }
    if (event?.target?.name === 'latitude') {
      setLatitude(event?.target?.value);
      setMapLoc({ ...mapLoc, latitude: event?.target?.value });
      setMapActionType('lat-lng');
    }
    if (event?.target?.name === 'longitude') {
      setLongitude(event?.target?.value);
      setMapActionType('lat-lng');
      setMapLoc({ ...mapLoc, longitude: event?.target?.value });
    }
    if (event?.target?.name === 'display_address') {
      setDisplayAddress(event?.target?.value);
    }
    if (event?.target?.value) {
      if (event?.target?.name !== 'price')
        delete DefaultFields[event?.target?.name];
      else if (event?.target?.name === 'house_number') {
        setHouseNumber(event.target.value);
        setMapActionType('address');
      }
      else if (event?.target?.name === 'address1') {
        setAddress1(event.target.value);
        setMapActionType('address');
      }
      else if (event?.target?.name === 'post_code') {
        setPostCode(event.target.value);
        setMapActionType('address');
      }
    } else {
      DefaultFields[event?.target?.name] = null;
    }
    CheckFormFieldValidity(event);
  }

  const HandleBlurFunc = (event) => {
    if (event?.target?.name === 'house_number') {
      setHouseNumber(event?.target?.value || '');
      setMapActionType('address');
    } else if (event?.target?.name === 'address1') {
      setAddress1(event?.target?.value || '')
      setMapActionType('address');
    } else if (event?.target?.name === 'post_code') {
      setPostCode(event?.target?.value || '')
      setMapActionType('address');
    }
  }

  const DecodePriceValue = (priceObj) => {
    set(DefaultFields, 'price', priceObj.floatValue);
    setPrice(priceObj.floatValue);
  }

  const FormSubmitFunc = async (event, additionalParams = {}) => {
    try {
      const Result = await props.HandleSubmitFunc(event, {
        formId: 'add-property-form',
        ...additionalParams,
        fields: { ...DefaultFields }
      });
      let selectBoxErrorObjTemp = {};
      if (isEmpty(selectBoxFieldErrors)) {
        selectBoxErrorObjTemp = { ...SelectBoxFieldDefaultErrors };
        setSelectBoxFieldErrors(selectBoxErrorObjTemp);
      } else if (find(selectBoxFieldErrors, function (sboxerror) {
        return (sboxerror.hasError)
      })) {
        selectBoxErrorObjTemp = selectBoxFieldErrors;
      }
      if (get(Result, 'formSubmit.hasError') || isEmpty(selectBoxErrorObjTemp)) {
        setFormError(Result.formSubmit.error);
      }
    } catch (error) {
      console.log('FormSubmitFunc error', error);
    }
  }

  const [tabKey, setTabKey] = useState("0")
  const MoveToTab = async (event, key, additionalParams = {}) => {
    setIsFormSaveTriggered(true);
    const ChildNodes = document.getElementsByClassName('tab-pane active')[0];
    const TabError = CheckFormValidity(ChildNodes.getElementsByClassName('form-control'),
      { isSubmit: false, isCustom: true });
    //Check any field has error
    let tabHasError = false;
    const TabErrorKeys = Object.keys(TabError),
      TabErrorKeysLength = TabErrorKeys.length;
    for (let index = 0; index < TabErrorKeysLength; index++) {
      tabHasError = TabError[TabErrorKeys[index]]?.hasError;
      if (tabHasError) {
        setFormError(TabError);
        break;
      }
    }
    //Check selectbox va;idations
    let selectBoxErrorObjTemp = {};
    if (tabKey === '0') {
      selectBoxErrorObjTemp = { ...selectBoxFieldErrors };
      if (isEmpty(selectBoxErrorObjTemp)) {
        selectBoxErrorObjTemp = { ...SelectBoxFieldDefaultErrors };
        setSelectBoxFieldErrors(selectBoxErrorObjTemp);
      } else if (!isEmpty(find(merge(SelectBoxFieldDefaultErrors, selectBoxFieldErrors), function (sboxerror) {
        return (sboxerror.hasError)
      }))) {
        selectBoxErrorObjTemp = merge(SelectBoxFieldDefaultErrors, selectBoxFieldErrors);
        setSelectBoxFieldErrors(selectBoxErrorObjTemp);
      }
      if (searchType?.value) {
        if (!priceQualifier?.value) {
          let selectBoxErrorObjTemp1 = { ...selectBoxErrorObjTemp, price_qualifier: { hasError: true } }
          setSelectBoxFieldErrors(selectBoxErrorObjTemp1);
          selectBoxErrorObjTemp = { ...selectBoxErrorObjTemp1 };
        } else {
          let selectBoxErrorObjTemp1 = { ...selectBoxErrorObjTemp, price_qualifier: { hasError: false } }
          setSelectBoxFieldErrors(selectBoxErrorObjTemp1);
          selectBoxErrorObjTemp = { ...selectBoxErrorObjTemp1 };
        }
      }
      if (department?.value) {
        if ((department.value === 'new_developments_residential') && (!newDevelopment?.value)) {
          let selectBoxErrorObjTemp1 = { ...selectBoxErrorObjTemp, new_development: { hasError: true } }
          setSelectBoxFieldErrors(selectBoxErrorObjTemp1);
          selectBoxErrorObjTemp = { ...selectBoxErrorObjTemp1 };
        } else {
          let selectBoxErrorObjTemp1 = { ...selectBoxErrorObjTemp, new_development: { hasError: false } }
          setSelectBoxFieldErrors(selectBoxErrorObjTemp1);
          selectBoxErrorObjTemp = { ...selectBoxErrorObjTemp1 };
        }
      }
      if (office?.value) {
        if ((negotiators.length > 0) && (!negotiator?.value)) {
          let selectBoxErrorObjTemp1 = { ...selectBoxErrorObjTemp, negotiator_id: { hasError: true } }
          setSelectBoxFieldErrors(selectBoxErrorObjTemp1);
          selectBoxErrorObjTemp = { ...selectBoxErrorObjTemp1 };
        } else {
          let selectBoxErrorObjTemp1 = { ...selectBoxErrorObjTemp, negotiator_id: { hasError: false } }
          setSelectBoxFieldErrors(selectBoxErrorObjTemp1);
          selectBoxErrorObjTemp = { ...selectBoxErrorObjTemp1 };
        }
      }
    } else if (tabKey === '1') {
      selectBoxErrorObjTemp = { ...selectBoxFieldErrors2 };
      if (isEmpty(selectBoxErrorObjTemp)) {
        selectBoxErrorObjTemp = { ...SelectBoxFieldDefaultErrors2 };
        setSelectBoxFieldErrors2(selectBoxErrorObjTemp);
      } else if (!isEmpty(find(merge(SelectBoxFieldDefaultErrors2, selectBoxFieldErrors2), function (sboxerror) {
        return (sboxerror.hasError)
      }))) {
        selectBoxErrorObjTemp = merge(SelectBoxFieldDefaultErrors2, selectBoxFieldErrors2);
        setSelectBoxFieldErrors2(selectBoxErrorObjTemp);
      }
    }
    if ((!tabHasError) && (isEmpty(find(selectBoxErrorObjTemp, function (serror) {
      return serror.hasError;
    })))) {
      if (additionalParams.isSubmit) {
        try {
          await FormSubmitFunc(event, additionalParams);
          setTabKey(key);
          setIsFormSaveTriggered(false);
        } catch (error) {
          console.log('additionalParams.isSave', error);
        }
      } else {
        setTabKey(key);
        setIsFormSaveTriggered(false);
      }
    }
    MoveToTopOfPage();
  };

  const SaveCurrentTab = (event) => {
    event.preventDefault();
    event.stopPropagation();
    MoveToTab(event, (parseInt(tabKey) + 1), {
      isSubmit: true,
      enableValidation: (parseInt(tabKey) === 5)
    });
  }

  const [buildingType, setBuildingType] = useState();
  const [searchType, setSearchType] = useState('');
  const [propertyStatus, setPropertyStatus] = useState();
  const [propertyStatusList, setPropertyStatusList] = useState([])
  const [buildingTypesList, setBuildingTypesList] = useState([]);
  const [floor, setFloor] = useState(DefaultSelectValue);
  const [priceQualifier, setPriceQualifier] = useState('');
  const [price, setPrice] = useState('');
  const [department, setDepartment] = useState('');
  const [crmId, setCrmId] = useState('');
  //Select box validations
  const SelectBoxFieldDefaultErrors = {
    department: {
      hasError: true,
      type: 'required'
    },
    search_type: {
      hasError: true,
      type: 'required'
    },
    status: {
      hasError: true,
      type: 'required'
    },
    building: {
      hasError: true,
      type: 'required'
    },
    office_crm_id: {
      hasError: true,
      type: 'required'
    },
    negotiator_id: {
      hasError: false,
      type: 'required'
    },
    price_qualifier: {
      hasError: false,
      type: 'required'
    },
  };
  const [selectBoxFieldErrors, setSelectBoxFieldErrors] = useState({});

  const SelectBoxFieldDefaultErrors2 = {
    floorarea_type: {
      hasError: true,
      type: 'required'
    },
    description: {
      hasError: true,
      type: 'required'
    }
  };
  const [selectBoxFieldErrors2, setSelectBoxFieldErrors2] = useState({});

  const HandleMultiSelect = (event, name, copyInAddParam) => {
    if (copyInAddParam) {
      const SelectedOptions = event.map(option => option.value)
      set(DefaultFields, `${name}`, SelectedOptions);
    }
    //SetValidation 
    //Single select validation
    if (name !== 'floorarea_type') {
      if (SelectBoxFieldDefaultErrors[name]) {
        if (event.value) {
          setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ [name]: { hasError: false } } });
        } else {
          setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ [name]: { hasError: (true) } } });
        }
      }
    } else {
      if (SelectBoxFieldDefaultErrors2[name]) {
        if (event.value) {
          setSelectBoxFieldErrors2({ ...selectBoxFieldErrors2, ...{ [name]: { hasError: false } } });
        } else {
          setSelectBoxFieldErrors2({ ...selectBoxFieldErrors2, ...{ [name]: { hasError: (true) } } });
        }
      }
    }
    switch (name) {
      case 'building':
        set(DefaultFields, `building.0`, event.value);
        setBuildingType(event);
        break;
      case 'floor':
        setFloor(event);
        break;
      case 'price_qualifier':
        setPriceQualifier(event);
        setFormError({ ...formError, ...{ price: { hasError: ((event.value !== 'POA') && (!price)), required: true, msg: 'POA only excepted' } } });
        break;
      case 'currency':
        set(DefaultFields, 'currency', event);
        setCurrencyOption(event);
        break;
      case 'department':
        setBuildingTypesList([]);
        setDepartment(event);
        const BuildingListTemp1 = event.value ? (searchType?.value ?
          (BuildingsList[event.value][searchType.value] ?
            BuildingsList[event.value][searchType.value] : BuildingsList[event.value]['default']) : []) : [];
        setBuildingTypesList(BuildingListTemp1);
        setBuildingType('');
        if (event.value) {
          setSelectBoxFieldErrors({
            ...selectBoxFieldErrors,
            ...{
              department: { hasError: false },
              building: { hasError: true },
              new_development: { hasError: (event.value === 'new_developments_residential')}
            }
          });
        }
        break;
      case 'search_type':
        setBuildingTypesList([]);
        setPriceQualifier();
        setPropertyStatus('');
        setBuildingType('')
        setPropertyStatusList((event?.value === 'sales') ? SaleStatus :
          (((event?.value === 'lettings') && (department?.value !== 'new_developments_residential')) ? RentalStatusOptions : []));
        const BuildingListTemp2 = event.value ? (department?.value ? (
          BuildingsList[department.value][event.value] ?
            BuildingsList[department.value][event.value] : BuildingsList[department.value]['default']) : []) : [];
        setBuildingTypesList(BuildingListTemp2);
        if (event.value) {
          setSelectBoxFieldErrors({
            ...selectBoxFieldErrors,
            ...{
              status: { hasError: (true) }, price_qualifier: { hasError: (true) },
              search_type: { hasError: false }, building: { hasError: (true) }
            }
          });
        }
        setSearchType(event);
        break;
      case 'status':
        setPropertyStatus(event);
        break;
      case 'county':
        let addressForMapCopy1 = [...addressForMap].splice(0, 0, event.value || '')
        setAddressForMap([...addressForMapCopy1]);
        setMapActionType('address');
        setCounty(event);
        setTown('');
        setTownJSON([]);
        setSubCounty('');
        setSubCountyJSON([]);
        //update town/city/area list
        let areaListTemp = [];
        if (event.value) {
          filter(AreasJson, function (area) {
            //Need change TODO
            if (area.level === 2) {
              if (area.parent === event.value) {
                areaListTemp.push({ value: area.name, label: area.name });
              }
            }
          })
        }
        setSubCountyJSON(areaListTemp);
        break;
      case 'sub_county':
        let addressForMapCopy2 = [...addressForMap].splice(1, 0, event.value || '', '')
        setAddressForMap(addressForMapCopy2);
        setMapActionType('address');
        setTown('');
        let areaListTemp1 = [];
        if (event.value) {
          filter(AreasJson, function (area) {
            //Need change TODO
            if (area.level === 3) {
              if (area.parent === event.value) {
                areaListTemp1.push({ value: area.name, label: area.name });
              }
            }
          })
        }
        setTownJSON(areaListTemp1);
        setSubCounty(event);
        break;
      case 'town':
        setTown(event);
        set(DefaultFields, 'area', event.value);
        let addressForMapCopy3 = [...addressForMap].splice(2, 0, event.value || '')
        setAddressForMap(addressForMapCopy3);
        setMapActionType('address');
        break;
      case 'office_crm_id':
        setOffice(event);
        setNegotiators([]);
        setNegotiator();
        //Get Negotiator call
        if (event?.id) {
          GetNegotiators(event.id);
          setSelectBoxFieldErrors(merge(selectBoxFieldErrors, { office_crm_id: { hasError: false } }));
        }
        if (negotiators.length > 0) {
          setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ negotiator_id: { hasError: (true) } } });
        } else {
          setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ negotiator_id: { hasError: false } } });
        }
        break;
      case 'negotiator_id':
        setNegotiator(event);
        if (event?.value) {
          setSelectBoxFieldErrors({
            ...selectBoxFieldErrors,
            ...{ negotiator_id: { hasError: false } }
          });
        }
        set(DefaultFields, 'crm_negotiator_id', event);
        set(DefaultFields, 'crm_negotiator_email', event.Email);
        break;
      case 'new_development':
        setNewDevelopment(event);
        if (event?.value) {
          setSelectBoxFieldErrors({
            ...selectBoxFieldErrors,
            ...{ new_development: { hasError: false } }
          });
        }
        break;
      default:
        break;
    }
  }

  const HandleDateChange = (value, name) => {
    if (name === 'completed_date') {
      setCompletedDate(value);
    } else if (name === 'exchanged_date') {
      setExchangedDate(value);
    }
    if (value) {
      delete DefaultFields[name];
    } else {
      DefaultFields[name] = null;
    }
  }

  const [checkboxValues, setCheckboxValues] = useState({});
  const HandleCheckBoxValue = (event) => {
    let checkBoxValueTemp = { [event.target.name]: event.target.checked };
    if ((event.target.name === 'is_enabled') && (!checkBoxValueTemp['is_enabled'])) {
      checkBoxValueTemp['is_show_on_website'] = false;
      checkBoxValueTemp['is_show_on_portal'] = false;
      checkBoxValueTemp['is_show_on_disposals_list'] = false;
    }
    setCheckboxValues({ ...checkboxValues, ...checkBoxValueTemp });
  }

  const [radioValues, setRadioValues] = useState({});
  const HandleRedioValue = (event) => {
    let RadioTempValues = {};
    set(RadioTempValues, event.target.name, event.target.value);
    setRadioValues({ ...radioValues, ...RadioTempValues });
  }

  const editorChange = (content, editor, fieldName) => {
    if (SelectBoxFieldDefaultErrors2[fieldName]) {
      if (content) {
        setSelectBoxFieldErrors2({ ...selectBoxFieldErrors2, ...{ [fieldName]: { hasError: false } } });
      } else {
        setSelectBoxFieldErrors2({ ...selectBoxFieldErrors2, ...{ [fieldName]: { hasError: true } } });
      }
    }
  }

  return (
    <>
      <Form id="add-property-form" noValidate onSubmit={(event) => SaveCurrentTab(event)}>
        <section className="agent-tabs">
          {
            isFormSaveTriggered && ((find((tabKey === '0' ? selectBoxFieldErrors : selectBoxFieldErrors2), function (sboxerror) {
              return (sboxerror.hasError)
            })) || (find(formError, function (error) {
              return (error.hasError)
            }))) && <Alert key="danger" variant="danger">
              Highlighted fields are required
            </Alert>
          }
          <Tabs
            defaultActiveKey={"0"}
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
            activeKey={tabKey}
            onSelect={(k) => MoveToTab({}, k)}
          >
            <Tab eventKey={0} title="Overview">
              <Row>
                <Col lg={12} className="d-flex justify-content-end">
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
              <Row>
                {/* Set crm provider default value */}
                <input type="hidden" name="crm_provider" value="property_uploader" />
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Department *</label>
                    <div
                      className={`custom-selectbox-form selectbox-dropdown ${(isFormSaveTriggered && selectBoxFieldErrors?.department?.hasError) ? ' pu-has-control-error' : ''}`}
                    >
                      <Select
                        name="department"
                        options={[
                          { value: "residential", label: "Residential" },
                          { value: "commercial", label: "Commercial" },
                          { value: "new_developments_residential", label: "New Developments" },
                        ]}
                        placeholder="Select department"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        value={department}
                        onChange={(event) => HandleMultiSelect(event, 'department')}
                        required={true}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                {
                  (department?.value === 'new_developments_residential') && (
                    <Col lg={6} className="mb-4">
                      <div className="form-wrapper">
                        <label>New Development *</label>
                        <div
                          className={`custom-selectbox-form selectbox-dropdown ${(isFormSaveTriggered && selectBoxFieldErrors?.new_development?.hasError) ? ' pu-has-control-error' : ''}`}
                        >
                          <Select
                            name="new_development"
                            options={newDevelopments}
                            placeholder="Select new development"
                            classNamePrefix={"select-opt"}
                            isSearchable={true}
                            value={newDevelopment}
                            onChange={(event) => HandleMultiSelect(event, 'new_development')}
                            required={department?.value === 'new_developments_residential'}
                          />
                        </div>
                      </div>
                    </Col>
                  )
                }
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Property Type *</label>
                    <div
                      className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.search_type?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="search_type"
                        options={[
                          { value: "sales", label: "Sales" },
                          { value: "lettings", label: "Lettings" },
                        ]}
                        placeholder="Select property type"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        value={searchType}
                        onChange={(event) => HandleMultiSelect(event, 'search_type')}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Property Status *</label>
                    <div
                      className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.status?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="status"
                        options={propertyStatusList}
                        placeholder="Select status"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={(event) => HandleMultiSelect(event, 'status')}
                        value={propertyStatus}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Building Type *</label>
                    <div
                      className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.building?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="building[0]"
                        options={buildingTypesList}
                        placeholder="Select building"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={(event) => HandleMultiSelect(event, 'building')}
                        value={buildingType}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Reference ID *</label>
                    <input type="text"
                      value={FORM_ID?.replace(/-/g, '').substring(0, 15)}
                      disabled={true}
                      className={`form-control${isFormSaveTriggered && formError?.crm_id?.hasError ? ' pu-has-control-error' : ''}`}
                      name="crm_id"
                      placeholder="Enter reference id"
                      onChange={(event) => HandleChangeFunc(event)}
                      required />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Office *</label>
                    <div
                      className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.office_crm_id?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="office_crm_id"
                        options={offices}
                        placeholder="Select office"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={(event) => HandleMultiSelect(event, 'office_crm_id')}
                        value={office}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              {
                (negotiators.length > 0) && <Row>
                  <Col lg={6} className="mb-4">
                    <div className="form-wrapper">
                      <label>Negotiator *</label>
                      <div
                        className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.negotiator_id?.hasError ? ' pu-has-control-error' : ''}`}>
                        <Select
                          name="negotiator_id"
                          options={negotiators}
                          placeholder="Select negotiator"
                          classNamePrefix={"select-opt"}
                          isSearchable={true}
                          onChange={(event) => HandleMultiSelect(event, 'negotiator_id')}
                          value={negotiator}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              }
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>County </label>
                    <div
                      className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.county?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="county"
                        options={countyJSON}
                        placeholder="Select county"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={(event) => HandleMultiSelect(event, 'county')}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>District/ Area </label>
                    <div
                      className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.sub_county?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="sub_county"
                        options={subCountyJSON}
                        placeholder="Select sub county"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={(event) => HandleMultiSelect(event, 'sub_county')}
                        value={subCounty}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              {
                townJSON.length > 1 && (
                  <Row>
                    <Col lg={6} className="mb-4">
                      <div className="form-wrapper">
                        <label>Town / City </label>
                        <div
                          className="custom-selectbox-form selectbox-dropdown">
                          <Select
                            name="town"
                            options={townJSON}
                            placeholder="Select town/city"
                            classNamePrefix={"select-opt"}
                            isSearchable={true}
                            onChange={(event) => HandleMultiSelect(event, 'town')}
                            value={town}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                )
              }
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>House Number / Name </label>
                    <input type="text"
                      className="form-control"
                      name="house_number"
                      placeholder="Enter house number/name"
                      onChange={(event) => HandleChangeFunc(event)}
                      onBlur={(event) => HandleBlurFunc(event)}
                    />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Street Name</label>
                    <input type="text"
                      className="form-control"
                      name="address1"
                      placeholder="Enter street name"
                      onChange={(event) => HandleChangeFunc(event)}
                      onBlur={(event) => HandleBlurFunc(event)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Postcode </label>
                    <input type="text"
                      className="form-control"
                      name="post_code"
                      placeholder="Enter postcode"
                      onChange={(event) => HandleChangeFunc(event)}
                      onBlur={(event) => HandleBlurFunc(event)}
                    />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Display Address </label>
                    <input type="text"
                      className="form-control"
                      name="display_address"
                      placeholder="Enter display address"
                      onChange={(event) => HandleChangeFunc(event)}
                      value={displayAddress}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Property Title </label>
                    <input type="text" className="form-control" name="extras.title"
                      placeholder="Enter property title" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Latitude </label>
                    <input type="text"
                      className="form-control"
                      name="latitude"
                      placeholder="Enter latitude"
                      onChange={(event) => HandleChangeFunc(event)}
                      value={latitude}
                    />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Longitude </label>
                    <input type="text"
                      className="form-control"
                      name="longitude"
                      placeholder="Enter longitude"
                      onChange={(event) => HandleChangeFunc(event)}
                      value={longitude}
                    />
                  </div>
                </Col>
              </Row>
              {
                (
                  (county?.value && subCounty?.value && (townJSON.length === 0 ? true : town?.value) &&
                    houseNumber && address1 && postCode)
                  || (
                    mapLoc.lat && mapLoc.lng
                  )
                ) && <Row>
                  <Col lg={12}>
                    <p>Change the position of the map marker by moving the map.</p>
                    <div className="main-wrapper-map">
                      {
                        <GoogleMap getMapLocation={getMapLocation}
                          mapLoc={{
                            lat: latitude ? parseFloat(latitude) : mapLoc.lat,
                            lng: longitude ? parseFloat(longitude) : mapLoc.lng
                          }}
                          inputAddress=
                          {county?.value && subCounty?.value && (townJSON.length === 0 ? true : town?.value) &&
                            houseNumber && address1 && postCode ?
                            `${houseNumber}, ${address1}, ${town.value ? `${town?.value}, ${subCounty?.value}` : subCounty?.value}, ${county?.value}, ${postCode}` : ''}
                          actionType={mapActionType}
                        />
                      }
                    </div>
                    <input type="hidden" name="latitude" value={mapLoc.lat || 51.509865} />
                    <input type="hidden" name="longitude" value={mapLoc.lng || -0.118092} />
                  </Col>
                </Row>
              }
              <Row>
                {
                  searchType?.value === 'sales' && <Col lg={6} className="mb-4">
                    <div className="form-wrapper">
                      {/* Sale status */}
                      <label>Price Qualifier *</label>
                      <div
                        className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.price_qualifier?.hasError ? ' pu-has-control-error' : ''}`}>
                        <Select
                          name="price_qualifier"
                          options={[
                            { value: "Asking price", label: "Asking price" },
                            { value: "Guide price", label: "Guide price" },
                            { value: "POA", label: "POA" },
                            { value: "Offers in excess of", label: "Offers in excess of" },
                            { value: "Offers in the region of", label: "Offers in the region of" },
                          ]}
                          placeholder="Select price qualifier"
                          classNamePrefix={"select-opt"}
                          isSearchable={true}
                          onChange={(event) => HandleMultiSelect(event, 'price_qualifier')}
                          value={priceQualifier}
                        />
                      </div>
                    </div>
                  </Col>
                }
                {
                  searchType?.value === 'lettings' && <Col lg={6} className="mb-4">
                    <div className="form-wrapper">
                      {/* Letting status */}
                      <label>Price Frequency *</label>
                      <div
                        className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors?.price_qualifier?.hasError ? ' pu-has-control-error' : ''}`}>
                        <Select
                          name="price_qualifier"
                          options={[
                            { value: "pa", label: "Per Annum" },
                            { value: "pcm", label: "Per Month" },
                            { value: "pw", label: "Per Week" },
                            { value: "POA", label: "POA" }
                          ]}
                          placeholder="Select price frequency"
                          classNamePrefix={"select-opt"}
                          isSearchable={true}
                          onChange={(event) => HandleMultiSelect(event, 'price_qualifier')}
                          value={priceQualifier}
                        />
                      </div>
                    </div>
                  </Col>
                }
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <Row>
                      <Col lg={3}>
                        <label>Currency</label>
                        <div className="custom-selectbox-form selectbox-dropdown">
                          <Select
                            name="currency"
                            options={CurrencyJson}
                            placeholder="Select currency"
                            classNamePrefix={"select-opt"}
                            isSearchable={false}
                            value={currencyOption}
                            onChange={(event => HandleMultiSelect(event, 'currency'))}
                            isDisabled={true}
                          />
                        </div>
                      </Col>
                      <Col lg={9}>
                        <label>Price {priceQualifier?.value === 'POA' ? '' : '*'}</label>
                        <CurrencyFormat
                          displayType={'input'} thousandSeparator={true}
                          name="price"
                          placeholder="Enter price"
                          className={`form-control${isFormSaveTriggered && formError?.price?.hasError ? ' pu-has-control-error' : ''}`}
                          required={priceQualifier?.value !== 'POA'}
                          onValueChange={(values) => DecodePriceValue(values)}
                          onChange={(event) => HandleChangeFunc(event)}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Available from</label>
                    <div className="form-datepicker">
                      <DatePicker
                        format="dd-MM-yyyy"
                        name="available_from"
                        onChange={setDateValue}
                        value={dateValue}
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Completed Date</label>
                    <div className="form-datepicker">
                      <DatePicker
                        format="dd-MM-yyyy"
                        onChange={(value) => HandleDateChange(value, 'completed_date')}
                        value={completedDate}
                        name="completed_date"
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                {searchType?.value === 'sales' && <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Exchanged Date</label>
                    <div className="form-datepicker">
                      <DatePicker
                        format="dd-MM-yyyy"
                        onChange={(value) => HandleDateChange(value, 'exchanged_date')}
                        value={exchangedDate}
                        name="exchanged_date"
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                      />
                    </div>
                  </div>
                </Col>
                }
                {
                  searchType?.value === 'lettings' && <Col lg={6} className="mb-4">
                    <div className="form-wrapper">
                      <label>Let Date</label>
                      <div className="form-datepicker">
                        <DatePicker
                          format="dd-MM-yyyy"
                          onChange={(value) => HandleDateChange(value, 'exchanged_date')}
                          value={exchangedDate}
                          name="exchanged_date"
                          dayPlaceholder="dd"
                          monthPlaceholder="mm"
                          yearPlaceholder="yyyy"
                        />
                      </div>
                    </div>
                  </Col>
                }
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <div className="form-check dashboard-checkbox me-5">
                        <input className="form-check-input" type="checkbox"
                          name="is_featured_property"
                          value={true}
                          id="featured"
                          onChange={event => HandleCheckBoxValue(event)}
                          checked={checkboxValues?.['is_featured_property']} />
                        <label className="form-check-label" for="featured">Is Featured Property</label>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "1")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={1} title="Property Details">
              <Row>
                <Col lg={12} className="d-flex justify-content-end">
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Floor</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="extras.floor"
                        options={[
                          DefaultSelectValue,
                          { value: "Lower ground floor", label: "Lower ground floor" },
                          { value: "Ground floor", label: "Ground floor" },
                          { value: "Floor 1", label: "Floor 1" },
                          { value: "Floor 2", label: "Floor 2" },
                          { value: "Floor 3", label: "Floor 3" },
                          { value: "Floor 4", label: "Floor 4" },
                          { value: "Floor 5", label: "Floor 5" }
                        ]}
                        placeholder="Select floor"
                        classNamePrefix={"select-opt"}
                        isSearchable={false}
                        onChange={(event) => HandleMultiSelect(event, 'floor')}
                        value={floor}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>No. of Bedrooms *</label>
                    <input type="number"
                      className={`form-control${isFormSaveTriggered && formError?.bedroom?.hasError ? ' pu-has-control-error' : ''}`}
                      name="bedroom"
                      min="0"
                      placeholder="Enter bedroom count"
                      required={true}
                      onChange={(event) => HandleChangeFunc(event)} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>No. of Bathrooms *</label>
                    <input type="number"
                      className={`form-control${isFormSaveTriggered && formError?.bathroom?.hasError ? ' pu-has-control-error' : ''}`}
                      name="bathroom"
                      min="0"
                      placeholder="Enter bathroom count"
                      required={true}
                      onChange={(event) => HandleChangeFunc(event)} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>No. of Receptions </label>
                    <input type="number"
                      className={`form-control${isFormSaveTriggered && formError?.reception?.hasError ? ' pu-has-control-error' : ''}`}
                      name="reception"
                      min="0"
                      placeholder="Enter reception count"
                      onChange={(event) => HandleChangeFunc(event)} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4} className="mb-4">
                  <div className="form-wrapper">
                    <label>Size(Min) *</label>
                    <input type="number"
                      className={`form-control${isFormSaveTriggered && formError?.floorarea_min?.hasError ? ' pu-has-control-error' : ''}`}
                      name="floorarea_min"
                      placeholder="Enter square footage min size"
                      required={true}
                      onChange={(event) => HandleChangeFunc(event)} />
                  </div>
                </Col>
                <Col lg={4} className="mb-4">
                  <div className="form-wrapper">
                    <label>Size(Max)</label>
                    <input type="number" className={`form-control${isFormSaveTriggered && formError?.floorarea_max?.hasError ? ' pu-has-control-error' : ''}`}
                      name="floorarea_max"
                      placeholder="Enter square footage max size"
                      onChange={(event) => HandleChangeFunc(event)} />
                  </div>
                </Col>
                <Col lg={4} className="mb-4">
                  <div className="form-wrapper">
                    <label>Units/ Measurements Type *</label>
                    <div
                      className={`custom-selectbox-form selectbox-dropdown ${isFormSaveTriggered && selectBoxFieldErrors2?.floorarea_type?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="floorarea_type"
                        options={[
                          { value: "squareFeet", label: "Square feet" },
                          { value: "squareMetres", label: "Square meters" },
                          { value: "Square yards", label: "Square yards" },
                          { value: "Hectares", label: "Hectares" },
                          { value: "Acres", label: "Acres" },
                        ]}
                        placeholder="Select units/ measurements type"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={event => HandleMultiSelect(event, 'floorarea_type')}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4} className="mb-4">
                  <div className="form-wrapper">
                    <label>BER Rating</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="extras.extrasField.pBERRating"
                        options={[
                          { label: "No Min. BER", value: null },
                          { label: "A1", value: "A1" },
                          { label: "A2", value: "A2" },
                          { label: "A3", value: "A3" },
                          { label: "B1", value: "B1" },
                          { label: "B2", value: "B2" },
                          { label: "B3", value: "B3" },
                          { label: "C1", value: "C1" },
                          { label: "C2", value: "C2" },
                          { label: "C3", value: "C3" },
                          { label: "D1", value: "D1" },
                          { label: "D2", value: "D2" },
                          { label: "E1", value: "E1" },
                          { label: "E2", value: "E2" },
                          { label: "F", value: "F" },
                          { label: "G", value: "G" },
                        ]}
                        placeholder="Select rating"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={4} className="mb-4">
                  <div className="form-wrapper">
                    <label>BER Number</label>
                    <input type="text" className="form-control"
                      name="extras.extrasField.pBERNumber"
                      placeholder="Enter number"
                      onChange={(event) => HandleChangeFunc(event)}
                    />
                  </div>
                </Col>
                <Col lg={4} className="mb-4">
                  <div className="form-wrapper">
                    <label>BER Performance Indicator </label>
                    <input type="text" className="form-control"
                      name="extras.extrasField.pEPI"
                      placeholder="Enter value"
                      onChange={(event) => HandleChangeFunc(event)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mb-4">
                  <div className="form-wrapper">
                    <label>Introduction </label>
                    <div>
                      <TextEditor
                        name="introtext"
                        additionalParams={props.additionalParams}
                        editorChange={(content, editor) => editorChange(content, editor, 'introtext')} />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mb-4">
                  <div className="form-wrapper">
                    <label>Description *</label>
                    <div
                      className={`${isFormSaveTriggered && selectBoxFieldErrors2?.description?.hasError ? 'text-editor-error' : ''}`}>
                      <TextEditor name="description"
                        additionalParams={props.additionalParams}
                        editorChange={(content, editor) => editorChange(content, editor, 'description')} />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Interiors</label>
                    <ul className="list-inline">
                      <li className="list-inline-item me-3">
                        <div class="form-check dashboard-radio radio-caps">
                          <input class="form-check-input" type="radio"
                            name="furnishing[0]"
                            id="furnished"
                            value="Furnished"
                            onChange={event => HandleRedioValue(event)}
                            checked={radioValues?.['furnishing']?.[0] === 'Furnished'} />
                          <label class="form-check-label" for="furnished">Furnished</label>
                        </div>
                      </li>
                      <li className="list-inline-item me-3">
                        <div class="form-check dashboard-radio radio-caps">
                          <input class="form-check-input" type="radio"
                            name="furnishing[0]"
                            id="semi-furnished"
                            value="Semi-furnished"
                            onChange={event => HandleRedioValue(event)}
                            checked={radioValues?.['furnishing']?.[0] === 'Semi-furnished'} />
                          <label class="form-check-label" for="semi-furnished">Semi-furnished</label>
                        </div>
                      </li>
                      <li className="list-inline-item me-3">
                        <div class="form-check dashboard-radio radio-caps">
                          <input class="form-check-input" type="radio"
                            name="furnishing[0]"
                            id="unfurnished"
                            value="Unfurnished"
                            onChange={event => HandleRedioValue(event)}
                            checked={radioValues?.['furnishing']?.[0] === 'Unfurnished'} />
                          <label class="form-check-label" for="unfurnished">Unfurnished</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Facilities</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="amenities"
                        isMulti={true}
                        options={facilities}
                        placeholder="Select facilities"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={event => HandleMultiSelect(event, 'amenities', true)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10} className="mb-4">
                  <div className="form-wrapper">
                    <label>Highlight Features</label>
                  </div>
                  <div className="d-flex justify-content-start btn-wrapper">
                    <div className="mb-0 w-100">
                      {
                        highLightFeatures.map((element, i) => (
                          <div key={i} className="mb-4 d-flex justify-content-start">
                            <div className="form-wrapper w-100">
                              <input type="text" className={`form-control`} autoFocus={i > 0}
                                name={`special[${i}]`}
                                onChange={(event) => handleHighLightFeaturesChange(event, i)}
                                placeholder="Enter highlight feature"
                                onKeyPress={(event) => handleHighLightFeaturesKP(event, i)}
                                value={element} />
                            </div>
                            <div className="d-flex justify-content-between btn-add-delete">
                              {(highLightFeatures.length === (i + 1)) &&
                                <button type="button" className="btn-none" onClick={() => addHighLightFeatures(i)}>
                                  <i className="icon icon-add-circle"></i>
                                </button>
                              }
                              {highLightFeatures.length > 1 &&
                                <button type="button" className="btn-none" onClick={() => removeHighLightFeatures(i)}>
                                  <i className="icon icon-delete-circle"></i>
                                </button>
                              }
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Video URL</label>
                    <input type="text" className="form-control"
                      name="video_tour[0].url"
                      placeholder="Enter video tour url" />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Virtual Tour URL</label>
                    <input type="text" className="form-control"
                      name="virtual_tour[0].url"
                      placeholder="Enter virtual tour url" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mb-4">
                  <div className="form-wrapper">
                    <h4 className="mb-10">Rooms</h4>
                    {
                      room_details?.map((room, i) => (
                        <div key={i} className="ms-3">
                          <Row>
                            <Col lg={6} className="mb-4">
                              <div className="form-wrapper">
                                <label>Name</label>
                                <input type="text" className="form-control"
                                  name={`room_details[${i}].name`}
                                  placeholder="Enter name"
                                  value={room.name}
                                  onChange={(event) => handleroom_detailsChange(event, i, 'name')}
                                  onKeyPress={(event) => handleroom_detailsKP(event, i)}
                                />
                              </div>
                            </Col>
                            <Col lg={6} className="mb-4">
                              <div className="form-wrapper">
                                <label>Dimension</label>
                                <input type="text" className="form-control"
                                  name={`room_details[${i}].dimensions`}
                                  placeholder="Enter dimension"
                                  value={room.dimensions}
                                  onChange={(event) => handleroom_detailsChange(event, i, 'dimensions')}
                                  onKeyPress={(event) => handleroom_detailsKP(event, i)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10} className="mb-4">
                              <div className="form-wrapper">
                                <label>Description</label>
                                <textarea className="form-control"
                                  name={`room_details[${i}].description`}
                                  value={room.description}
                                  placeholder="Enter description"
                                  onChange={(event) => handleroom_detailsChange(event, i, 'description')}
                                  rows="3" cols="50">
                                </textarea>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12} className="mb-4">
                              <div className="form-wrapper">
                                {room_details.length > 1 &&
                                  <Button variant="secondary" className="btn-secondary-dashboard me-3"
                                    onClick={() => removeroom_details(i)}>Delete</Button>
                                }
                                {(room_details.length === (i + 1)) &&
                                  <Button variant="primary" className="btn-primary-dashboard d-md-inline d-none"
                                    onClick={() => addroom_details(i)}>Add More Room</Button>
                                }
                              </div>
                            </Col>
                          </Row>
                          <hr />
                        </div>
                      ))
                    }
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10} className="mb-4">
                  <div className="form-wrapper">
                    <label>Agent Notes (Not displayed)</label>
                  </div>
                  <div className="d-flex justify-content-start btn-wrapper">
                    {/* {agentNotes} */}
                    <div className="mb-0 w-100">
                      {
                        agentNotes.map((element, i) => (
                          <div key={i} className="mb-4 d-flex justify-content-start">
                            <div className="form-wrapper w-100">
                              <input type="text" className={`form-control`}
                                name={`agent_notes[${i}]`}
                                onChange={(event) => handleAgentNotesChange(event, i)}
                                placeholder="Enter agent note"
                                value={element} />
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Private Notes (Not displayed)</label>
                    <input type="text" className="form-control" name="private_notes"
                      placeholder="Enter private note" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "0")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "2")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={2} title="Images">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p>The order of the assets below is the order they will be shown on the website.</p>
                <div className="text-end">
                  <Button className="btn-primary-dashboard" onClick={() => showModalImagesFunc()}>Add</Button>
                </div>
              </div>
              {/* ModalPopUp */}
              {
                showModalImages && <ModalPopUp
                  modalShow={showModalImages}
                  getUploadedData={getUploadedData}
                  modalAction={'upload'}
                  setModalShowFunc={setShowModalImagesFunc}
                  uploadedDataFieldName="images"
                  parentPath="properties"
                  format_text=".png, .jpg, .jpeg, .webp"
                  accept_format={{
                    'image/*': ['.jpeg', '.png', '.jpg', '.webp']
                  }}
                />
              }
              {/* UploadedItemsTable */}
              <StatcTable
                menu="Properties"
                parentPath="file"
                allItems={allUploadedItems.filter(item => (item.field === 'images'))}
                getUploadedData={getUploadedData}
              />
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "1")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "3")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={3} title="Floorplan">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p>The order of the assets below is the order they will be shown on the website.</p>
                <div className="text-end">
                  <Button className="btn-primary-dashboard" onClick={() => showModalFloorplanFunc()}>Add</Button>
                </div>
              </div>
              {/* ModalPopUp */}
              {
                showModalFloorplan && <ModalPopUp
                  modalShow={showModalFloorplan}
                  getUploadedData={getUploadedData}
                  modalAction={'upload'}
                  setModalShowFunc={setShowModalFloorplanFunc}
                  uploadedDataFieldName="floorplan"
                  parentPath="properties"
                  format_text=".png, .jpg, .jpeg, .webp"
                  accept_format={{
                    'image/*': ['.jpeg', '.png', '.jpg', '.webp']
                  }}
                />
              }
              {/* UploadedItemsTable */}
              <StatcTable
                menu="Properties"
                parentPath="file"
                allItems={allUploadedItems.filter(item => (item.field === 'floorplan'))}
                getUploadedData={getUploadedData}
              />
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "2")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "4")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={4} title="BER">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p>The order of the assets below is the order they will be shown on the website.</p>
                <div className="text-end">
                  <Button className="btn-primary-dashboard" onClick={() => showModalEPCFunc()}>Add</Button>
                </div>
              </div>
              {
                showModalEPC && <ModalPopUp
                  modalShow={showModalEPC}
                  getUploadedData={getUploadedData}
                  modalAction={'upload'}
                  setModalShowFunc={setShowModalEPCFunc}
                  uploadedDataFieldName="epc"
                  parentPath="properties"
                  format_text=".png, .jpg, .jpeg, .webp, .pdf"
                  accept_format={{
                    'image/*': ['.jpeg', '.png', '.jpg', '.webp'],
                    'application/pdf': ['.pdf']
                  }}
                />
              }
              {/* UploadedItemsTable */}
              <StatcTable
                menu="Properties"
                parentPath="file"
                allItems={allUploadedItems.filter(item => (item.field === 'epc'))}
                getUploadedData={getUploadedData}
              />
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "3")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "5")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={5} title="Brochure">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p>The order of the assets below is the order they will be shown on the website.</p>
                <div className="text-end">
                  <Button className="btn-primary-dashboard" onClick={() => showModalBrochureFunc()}>Add</Button>
                </div>
              </div>
              {/* ModalPopUp */}
              {
                showModalBrochure && <ModalPopUp
                  modalShow={showModalBrochure}
                  getUploadedData={getUploadedData}
                  modalAction={'upload'}
                  setModalShowFunc={setShowModalBrochureFunc}
                  uploadedDataFieldName="brochure"
                  parentPath="properties"
                  format_text=".pdf"
                  accept_format={{
                    'application/pdf': ['.pdf']
                  }}
                />
              }
              {/* UploadedItemsTable */}
              <StatcTable
                menu="Properties"
                parentPath="file"
                allItems={allUploadedItems.filter(item => (item.field === 'brochure'))}
                getUploadedData={getUploadedData}
              />
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "4")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Submit</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </section>
      </Form>
    </>
  )
}

const AddProperty = (props) => {
  return <CommonAddItemComp PuFormControlsAdd={
    (HandleSubmitFunc) => {
      return (
        <>
          <AddFormControls
            {...props} HandleSubmitFunc={HandleSubmitFunc}
          />
        </>
      )
    }
  } {...props} DisableDefaultFormAction={true} />
}

export default AddProperty;
